import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { useModal, useModalUpdate } from "../../context/ModalContext";

import { useVideoPlayer, useVideoPlayerUpdate } from "../../../components/context/VideoContext";
import { Slide21_1 } from '../../../assets/Images';
import './Slide.css';
import './Slide21.css';
import { Video_Efficacy_Overall } from '../../../assets/video';

const Slide21 = () => {

    // get/set json data
    const getData = useData();

    // get/set json data
    const getVideo = useVideoPlayer();
    const setVideo = useVideoPlayerUpdate();

    // Modal
    const popupModalSet = useModalUpdate();

    useEffect(() => {
        
    }, []);

    const openModal = (e) => {
        popupModalSet(true);
    }


    return (
        <div id="slide21">
        <div className="video-wrap">
            { <Player vid={Video_Efficacy_Overall} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[2].slides[1].title}</h1>
            <div className="slide-body-wrap">
                <div className="slide-copy-wrap">
                    <div className="slide-copy">
                        <p>The three blinded reading groups expressed in majority <span>no preference</span> between images with ELUCIREM™ and Gadovist<sup>®</sup> although <span>a preference was more often reported with ELUCIREM™</span> than with Gadovist<sup>®</sup></p>
                    </div>
                </div>
                <div className="image-wrap flex-center fade">
                    <img src={Slide21_1} alt="Chart" />
                </div>
            </div>
        </div>
        </div>

    );
};

export default Slide21