import React from 'react';
import { Slide22_1 } from '../../../assets/Images';
import './Slide22.css';

const Slide22_1_popup = () => {
    return (
        <div className="popup">
            <h2>Percentage of enhancement</h2>
            <div className="popup-img-wrap">
                <img src={Slide22_1} alt="Percentage of enhancement" />
            </div>
            
        </div>
    );
};

export default Slide22_1_popup;