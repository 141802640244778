import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { useModal, useModalUpdate } from "../../context/ModalContext";

import { useVideoPlayer, useVideoPlayerUpdate } from "../../../components/context/VideoContext";
import { Slide20_1 } from '../../../assets/Images';
import './Slide.css';
import './Slide20.css';
import { Video_Efficacy_Lession } from '../../../assets/video';

const Slide20 = () => {

    // get/set json data
    const getData = useData();

    // get/set json data
    const getVideo = useVideoPlayer();
    const setVideo = useVideoPlayerUpdate();

    // Modal
    const popupModalSet = useModalUpdate();

    useEffect(() => {
        
    }, []);

    const openModal = (e) => {
        popupModalSet(true);
    }


    return (
        <div id="slide20">
        <div className="video-wrap">
            { <Player vid={Video_Efficacy_Lession} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[2].slides[0].title}</h1>
            <div className="slide-body-wrap">
                <div className="slide-copy-wrap">
                    <div className="slide-copy">
                        <p>ELUCIREM™ (0.05 mmol/kg) demonstrated <span>non-inferiority in terms of lesion visualization</span> vs Gadovist<sup>®</sup> (0.1 mmol/kg)</p>
                    </div>
                </div>
                <div className="image-wrap flex-center fade">
                    <img src={Slide20_1} alt="Chart" />
                </div>
            </div>
        </div>
        </div>

    );
};

export default Slide20;