import React, { useContext } from 'react';

const videoPlayerContext = React.createContext();
const videoPlayerUpdateContext = React.createContext();

export function useVideoPlayer() {
   return useContext(videoPlayerContext);
}

export function useVideoPlayerUpdate() {
    return useContext(videoPlayerUpdateContext)
}

export function VideoProvider({ children }) {

    const [videoplayer,setVideoplayer] = React.useState(null);
    function setVideo(_video) {
        setVideoplayer(_video);
    }
    
    return (

        <videoPlayerContext.Provider value={videoplayer}>
            <videoPlayerUpdateContext.Provider value={setVideo}>
                {children}
            </videoPlayerUpdateContext.Provider>
        </videoPlayerContext.Provider>

    )
}
