import React, { useState } from 'react';
import VideoJS from './VideoJS';
import { useVideoPlayer, useVideoPlayerUpdate } from "../../components/context/VideoContext";
import './Video.css';
import { Video_Poster } from '../../assets/video';

const Player = (props) => {

    // get/set json data
    const getVideo = useVideoPlayer();
    const setVideo = useVideoPlayerUpdate();

   const playerRef = React.useRef(null);
   const videoJsOptions = {
      poster: !props.poster ? `${Video_Poster}` : props.poster,
      class: "video-js vjs-default-skin vjs-big-play-centered vjs-show-big-play-button-on-pause",
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      sources: [{
      src: props.vid,
      src: props.vid,
      type: 'video/mp4'
      }]
   };

   const handlePlayerReady = (player) => {

    //  playerRef.current = player;

//     //  player.pause(true);
 
    //  You can handle player events here, for example:
     player.on('waiting', () => {
    //   console.log('player is waiting');
     });
 
    //  player.on('dispose', () => {
    //   // playerRef.log('player will dispose');
    //  });

    player.on("timeupdate", function () {
        // withIdleTimer.pause()
        // // console.log(withIdleTimer);
        
    });

    player.on("pause", function () {
        // console.log("pause");
    });

    player.on("play", function () {
    });
     
    player.on("ended", function(){
        player.bigPlayButton.show();
        player.currentTime(0);
        player.hasStarted(false);
        player.trigger("ready");
    })


   };

    return (
        
        <div className="video-holder">
            <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
        </div>
        
    );
};



export default Player;