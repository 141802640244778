import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { useModal, useModalUpdate } from "../../context/ModalContext";

import { useVideoPlayer, useVideoPlayerUpdate } from "../../../components/context/VideoContext";
import { Slide24_1, Slide24_2 } from '../../../assets/Images';
import './Slide.css';
import './Slide24.css';
import { Video_Patient_2 } from '../../../assets/video';

const Slide24 = () => {

    // get/set json data
    const getData = useData();

    // get/set json data
    const getVideo = useVideoPlayer();
    const setVideo = useVideoPlayerUpdate();

    // Modal
    const popupModalSet = useModalUpdate();

    useEffect(() => {
        
    }, []);

    const openModal = (e) => {
        popupModalSet(true);
    }


    return (
        <div id="slide24">
        <div className="video-wrap">
        { <Player vid={Video_Patient_2} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[2].slides[4].title}</h1>
            <div className="slide-body-wrap">

                <div className="slide-copy-wrap">
                    <div className="slide-copy">
                        <p><span>66-year-old male</span> with hepatocellular cancer</p>
                    </div>
                </div>

                <div className="image-wrap flex-center">
                    <div className="col-wrap">
                        <div className="image-col color-fade">
                            <img src={Slide24_1} alt="ELUCIREM™ (gadopiclenol) 0.05 mmol/kg" />
                            <p>ELUCIREM™ (gadopiclenol) 0.05 mmol/kg</p>
                        </div>
                        <div className="image-col">
                            <img src={Slide24_2} alt="Gadovist® (gadobutrol) 0.1 mmol/kg" />
                            <p>Gadovist<sup>®</sup> (gadobutrol) 0.1 mmol/kg</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>

    );
};

export default Slide24;