import React, { useEffect } from 'react';
import { useModalContent } from "../../components/context/ModalContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './ModalContent.css'
import Slide01_1_popup from '../Slides/Slide/Slide01_1_popup';
import Slide22_1_popup from '../Slides/Slide/Slide22_1_popup';
import Slide22_2_popup from '../Slides/Slide/Slide22_2_popup';

const ModalContent = (props) => {

    // Modal content
    const getModalContent = useModalContent();

    return (
        <div id="modal">
            <div className="modal-header-transparent">
                <div className="close-btn" onClick={props.close}><FontAwesomeIcon icon={faTimes} /></div>
            </div>
            <div id="modal-content" className="modal-content">
                { getModalContent === "slide01-1" ? <Slide01_1_popup /> : null }

                { getModalContent === "slide22-1" ? <Slide22_1_popup /> : null }
                { getModalContent === "slide22-2" ? <Slide22_2_popup /> : null }
            </div>
        </div>
    );
    
};

export default ModalContent;