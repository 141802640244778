import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';

import { Slide10_1 } from '../../../assets/Images';

import './Slide.css';
import './Slide10.css';
import { Video_Methodology_StudyDesign } from '../../../assets/video';

const Slide10 = () => {

    // get/set json data
    const getData = useData();

    useEffect(() => {
        
    }, []);


    return (
        <div id="slide10">
        <div className="video-wrap">
            { <Player vid={Video_Methodology_StudyDesign} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[1].slides[0].title}</h1>

            <div className="slide-body-wrap">
                <div className="slide-copy-wrap">
                    <div className="slide-copy">
                        <p>The PROMISE trial was a <span>multi&#8209;center, prospective, randomized, double-blind, controlled</span> and <span>cross&#8209;over phase III study</span> conducted in 11 countries</p>
                    </div>
                </div>
                <div className="image-wrap flex-center fade">
                    <img src={Slide10_1} alt="Chart" />
                </div>
            </div>
        </div>
        </div>

    );
};

export default Slide10;