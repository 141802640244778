import React from "react";

export const renderFormattedText = ( html, classActive ) => {
    if ( classActive === null ) {
      return (
      <div dangerouslySetInnerHTML={{ __html: html }} ></div>
      )
    } else {
      return (<div className={classActive} dangerouslySetInnerHTML={{ __html: html }} ></div>)
    }
  };

