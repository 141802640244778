import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { useModalUpdate, useModalContentUpdate } from "../../context/ModalContext";

import { Slide01_1 } from '../../../assets/Images';

import './Slide.css';
import './Slide01.css';
import { Video_Intro_NextGen } from '../../../assets/video';

const Slide01 = () => {

    // get/set json data
    const getData = useData();

    // Modal
    const popupModalSet = useModalUpdate();

    // Modal content
    const modalContentSet = useModalContentUpdate();


    const openModal = (e) => {
        modalContentSet(e);
        popupModalSet(true);
    }

    return (
        <div id="slide01">
        <div className="video-wrap">
            { <Player vid={Video_Intro_NextGen} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[0].slides[1].title}</h1>
            
            <div className='slide-body-wrap'>
                <div className="image-wrap">
                    <div className="col-wrap">
                        <div className="text-col">
                                <ul>
                                    <li><span>ELUCIREM™ has 2- to 3-fold greater relaxivity</span> than currently approved GBCAs<sup>1</sup></li>
                                    <li><span>ELUCIREM™ has a high kinetic stability</span> compared to other GBCAs, with a longer dissociation half-life of 20 days vs 18 hours under acidic conditions<sup>1</sup></li>
                                </ul>
                        </div>
                        <div className="image-col fade" onClick={() => openModal('slide01-1')}>
                            <img src={Slide01_1} alt="Graph" />
                            <div className="expand"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>

    );
};

export default Slide01;