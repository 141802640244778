import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { useModalUpdate, useModalContentUpdate } from "../../context/ModalContext";

import { useVideoPlayer, useVideoPlayerUpdate } from "../../../components/context/VideoContext";
import { Slide22_1, Slide22_2 } from '../../../assets/Images';
import './Slide.css';
import './Slide22.css';
import { Video_Efficacy_Quantitative } from '../../../assets/video';

const Slide22 = () => {

    // get/set json data
    const getData = useData();

    // get/set json data
    const getVideo = useVideoPlayer();
    const setVideo = useVideoPlayerUpdate();

    // Modal
    const popupModalSet = useModalUpdate();

    // Modal content
    const modalContentSet = useModalContentUpdate();


    const openModal = (e) => {
        modalContentSet(e);
        popupModalSet(true);
    }


    return (
        <div id="slide22">
            <div className="video-wrap">
                { <Player vid={Video_Efficacy_Quantitative} /> }
            </div>
        <div className="slide">
            <h1>{getData.talkingpaper[2].slides[2].title}</h1>
            <div className="slide-body-wrap">
                <div className="slide-copy-wrap">
                    <div className="slide-copy">
                        <ul>
                            <li><span>Percentage of enhancement was significantly higher</span> with ELUCIREM™ for two reading groups</li>
                            <li>For <span>lesion to background ratio</span> there were no significant differences between ELUCIREM™ and Gadovist<sup>®</sup></li>
                        </ul>
                    </div>
                </div>

                <div className="image-wrap flex-center">
                    <div className="col-wrap">
                        <div className="image-col" onClick={() => openModal("slide22-1")}>
                            <p>Enhancement percentage</p>
                            <img src={Slide22_1} alt="Downward arrow" />
                            <div className="expand"></div>
                        </div>
                        <div className="image-col" onClick={() => openModal("slide22-2")}>
                            <p>Lesion-to-background ratio</p>
                            <img src={Slide22_2} alt="Tick" />
                            <div className="expand"></div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>

    );
};

export default Slide22;