import React, { useEffect } from 'react';
import { useScreenTypeUpdate } from "../../components/context/MixContext";
import { motion } from "framer-motion";

import GuerbetLogo from '../../assets/Images/guerbet-logo.svg';

import './Home.css';

const Home = () => {

    // screen type
    const setScreenType = useScreenTypeUpdate();

    const handleEnterClick = () => {
        setScreenType('intro');
    }

    useEffect(() => {
        const footnote = document.getElementById("footnotes");
        footnote.classList.add("footnotes-wide");
        footnote.innerHTML = "<p class='mb10'>The statements made in this presentation reflect only the personal opinions and experiences of the speaker and do not necessarily reflect the opinions of Guerbet or any other institution with which they are affiliated. Please consult the prescribing information in your country.</p><p>P24002715, March 2024</p><p>Trademarks are the property of their respective owners.</p>";
    }, []);

    return (

        <motion.div id="home"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }} 
            transition={{ delay: .2, duration: .5 }}>
                <div className="home">
                    <div className="guerbet-logo"><img src={GuerbetLogo} alt="Guerbet Logo" /></div>
                    <div className="hompage-text">
                        <h2>TALKING PAPER:</h2>
                        <h1>The PROMISE Trial</h1>
                        <div className="enter-btn" onClick={() => { handleEnterClick(); }}>start</div>
                    </div>
                </div>
        </motion.div>
    );
};

export default Home;