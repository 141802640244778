import React from 'react';

import { useCurrentModule, useCurrentModuleUpdate, useScreenType, useScreenTypeUpdate, useNavFrom, useNavFromUpdate } from "../../components/context/MixContext";

import {Logo} from '../../assets/Images';

import './Footer.css';

const Footer = () => {

    // current module
    const getModule = useCurrentModule();
    const setModule = useCurrentModuleUpdate();

    // screen type
    const getScreenType = useScreenType();
    const setScreenType = useScreenTypeUpdate();

    // nav from
    const getNavFrom = useNavFrom();
    const setNavFrom = useNavFromUpdate();



    return (
        <>
        <div id="footnotes" className="footnotes"></div>
        <div className="logo"><img src={Logo} alt="Elucirem Logo" /></div>
        </>
    );
};

export default Footer;