import React, { useContext } from 'react';

const dataContext = React.createContext();
const dataUpdateContext = React.createContext();

export function useData() {
   return useContext(dataContext);
}

export function useDataUpdate() {
    return useContext(dataUpdateContext)
}

export function DataProvider({ children }) {

    const [data,setData] = React.useState(null);
    function setJsonData(_data) {
        setData(_data);
    }
    
    return (

        <dataContext.Provider value={data}>
            <dataUpdateContext.Provider value={setJsonData}>
                {children}
            </dataUpdateContext.Provider>
        </dataContext.Provider>

    )
}