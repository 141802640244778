import React, { useState } from 'react';
import { useData } from "../../components/context/DataContext";
import { useCurrentModule, useCurrentModuleUpdate, useCurrentSwiper, useCurrentSwiperUpdate, useScreenType, useScreenTypeUpdate, useNavFrom, useNavFromUpdate } from "../../components/context/MixContext";

import { motion } from "framer-motion";

import './Header.css';

const Header = (props) => {


    const [getActiveMen, setActiveMen] = useState();

    // get/set json data
    const getData = useData();

    // current module
    const getModule = useCurrentModule();
    const setModule = useCurrentModuleUpdate();

    // current swiper
    const getSwiper = useCurrentSwiper();
    const setSwiper = useCurrentSwiperUpdate();

    // screen type
    const getScreenType = useScreenType();
    const setScreenType = useScreenTypeUpdate();

    // nav from
    const getNavFrom = useNavFrom();
    const setNavFrom = useNavFromUpdate();

    const checkExist = (_slide) => {
        const checkIntv = setInterval(function() {
        const currentSwiper = document.getElementById("mySwiper");
        console.log(currentSwiper.ref);
        if ( currentSwiper ) {
            clearInterval(checkIntv);
            // currentSwiper.slideTo(_slide)
            console.log(getSwiper);
            // swiper = currentSwiper
            // console.log(swiper);
        }
        }, 100); // check every 100ms
    }



    const handleMenuClick = (_section, _screen, _level, e) => {

        if ( getData.talkingpaper[_section].slides.length < 2 ) {
            setScreenType("tp");
            setModule(_section);
            setNavFrom([props.navfrom, _screen] );
            if ( props.navfrom === "tp" ) {
                getSwiper.slideTo(_screen);
            }
        } else {

            const menuBlocker = document.getElementById("menu-blocker");
            menuBlocker.classList.add("show-blocker");
            if ( _level === "level1" ) {
                const currentSub = document.getElementById("sub"+_section);
                if ( currentSub.classList.contains("active-sub") ) {
                    currentSub.classList.remove("active-sub");
                } else {
                    currentSub.classList.add("active-sub");
                }
                hideNoSubs(currentSub);  
            }
            if ( _level === "level2" ) {
                
                hideSubs();
                setScreenType("tp");
                menuBlocker.classList.remove("show-blocker");
                setModule(_section);
                setNavFrom([props.navfrom, _screen] );
                if ( props.navfrom === "tp" ) {
                    checkExist(_screen)
                    getSwiper.slideTo(_screen);
                }
            }
            
        }
        
    }

    const hideNoSubs = (_currentSub) => {
        document.querySelectorAll('.submenu').forEach(function(_menuitem) {
            if ( _currentSub === _menuitem) {
                // console.log(_currentSub)
            } else {
                 _menuitem.classList.remove("active-sub");
            }
        });
    }
    const hideSubs = () => {
        document.querySelectorAll('.submenu').forEach(function(_sub) {
            _sub.classList.remove("active-sub");
        });
    }
    const hideBlocker = (e) => {
        hideSubs();
        e.target.classList.remove("show-blocker");
        // const menuBlocker = document.getElementById("menu-blocker");
    }

    return (
        <>
             <div id="menu-blocker" className="menu-blocker" onClick={(e) => { hideBlocker(e); }}></div>
            <div className="header">
                <div className="menu">
                {getData && getData.talkingpaper.map(function(d, index1){
                    return (
                        <div key={`col ${index1}`} className="menu-column">
                            <div className={ getModule === d.sectionindex && props.navfrom === "tp" ? "level1 menu-item-active" : "level1"} key={`k ${index1}`} data-mod={d.sectionindex} data-screen={index1} onClick={(e) => { handleMenuClick(d.sectionindex, d.index, "level1", e); }}>{d.section}</div>
                            
                            <div id={`sub${d.sectionindex}`} className="submenu">
                                {d.slides.map(function(s, index2){
                                    return (
                                        <div className="level2" key={`sk ${index2}`} onClick={(e) => { handleMenuClick(d.sectionindex, index2, "level2", e); }} >{d.slides[index2].title}</div>
                                    )
                                })}
                                <div key={`tri ${index1}`} className="triUp"></div>
                            </div>
                        </div>
                    )
                })} 
                </div>
            </div>
        </>
    );
};

export default Header;