import React, { useState } from 'react';
import MultiSwiper from '../../MultiSwiper/MultiSwiper';

import { useCurrentModule, useCurrentModuleUpdate } from "../../../components/context/MixContext";
import { useData, useDataUpdate } from "../../../components/context/DataContext";

// Introduction
import Slide00 from '../../Slides/Slide/Slide00';
import Slide01 from '../../Slides/Slide/Slide01';
import Slide02 from '../../Slides/Slide/Slide02';

// Methodology
import Slide10 from '../../Slides/Slide/Slide10';
import Slide11 from '../../Slides/Slide/Slide11';
import Slide12 from '../../Slides/Slide/Slide12';
import Slide13 from '../../Slides/Slide/Slide13';

// Efficacy
import Slide20 from '../../Slides/Slide/Slide20';
import Slide21 from '../../Slides/Slide/Slide21';
import Slide22 from '../../Slides/Slide/Slide22';
import Slide23 from '../../Slides/Slide/Slide23';
import Slide24 from '../../Slides/Slide/Slide24';

// Safety Profile
import Slide30 from '../../Slides/Slide/Slide30';

// Conclusions
import Slide40 from '../../Slides/Slide/Slide40';



const SlideSection = () => {

    // get/set json data
    const getData = useData();

    // current module
    const getModule = useCurrentModule();
    const setModule = useCurrentModuleUpdate();

    const introduction_slides = [Slide00, Slide01, Slide02]
    const methodology_slides = [Slide10, Slide11, Slide12, Slide13]
    const efficacy_slides = [Slide20, Slide21, Slide22, Slide23, Slide24]
    const safetyprofile_slides = [Slide30]
    const conclusions_slides = [Slide40]

    return (
        <div>
            { getModule === 0 ? <MultiSwiper data={getData.talkingpaper[0]} classname="introduction" slides={introduction_slides} pagination="introduction" /> : null }
            { getModule === 1 ? <MultiSwiper data={getData.talkingpaper[1]} classname="methodology" slides={methodology_slides} pagination="methodology" /> : null }
            { getModule === 2 ? <MultiSwiper data={getData.talkingpaper[2]} classname="efficacy" slides={efficacy_slides} pagination="efficacy" /> : null }
            { getModule === 3 ? <MultiSwiper data={getData.talkingpaper[3]} classname="safety-profile" slides={safetyprofile_slides} pagination="safety-profile" /> : null }
            { getModule === 4 ? <MultiSwiper data={getData.talkingpaper[4]} classname="conclusions" slides={conclusions_slides} pagination="conclusions" /> : null }
        </div>
    );
};

export default SlideSection;