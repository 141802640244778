import React from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { Slide00_1 } from '../../../assets/Images';

import './Slide.css';
import './Slide00.css';
import { Video_Introduction } from '../../../assets/video';

const Slide00 = () => {

    // get/set json data
    const getData = useData();


    return (
        <div id="slide00">
        <div className="video-wrap">
            { <Player vid={Video_Introduction} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[0].slides[0].title}</h1>

            <div className='slide-body-wrap'>
                <div className="slide-copy-wrap ">
                    <div className="slide-copy">
                        <p>Following reports of nephrogenic systemic fibrosis (NSF) and gadolinium retention associated with the use of some GBCAs, <span>major health authorities</span> such as the <span>EMA, recommend</span>:</p>
                    </div>
                </div>
            
                <div className="image-text-wrap fade">
                    <div className="col-wrap">
                        <div className="image-col">
                            <img src={Slide00_1} alt="Downward arrow" />
                        </div>
                        <div className="text-col">
                            <p>This is especially important for the repetitive use of contrast-enhanced MRI, for example:</p>
                            <ul>
                                <li>In patients undergoing surveillance or treatment follow-up</li>
                                <li>In susceptible individuals such as pediatric patients</li>
                                <li>In women undergoing MRI for screening, such as women at high risk of breast cancer or women with dense breast tissue who undergo breast MRI for screening</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>

    );
};

export default Slide00;