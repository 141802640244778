import React from 'react';
import { Slide22_2 } from '../../../assets/Images';
import './Slide22.css';

const Slide22_1_popup = () => {
    return (
        <div className="popup">
            <h2>Lesion-to-background ratio</h2>
            <div className="popup-img-wrap">
                <img src={Slide22_2} alt="Lesion-to-background ratio" />
            </div>
            
        </div>
    );
};

export default Slide22_1_popup;