import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { useModal, useModalUpdate } from "../../context/ModalContext";

import { useVideoPlayer, useVideoPlayerUpdate } from "../../../components/context/VideoContext";


import { Slide13_1, Slide13_2, Slide13_3, Slide13_4, Slide13_5, Slide13_6, Slide13_7} from '../../../assets/Images';


import './Slide.css';
import './Slide13.css';
import { Video_Methodology_Patients } from '../../../assets/video';

const Slide13_ = () => {

    // get/set json data
    const getData = useData();

    // get/set json data
    const getVideo = useVideoPlayer();
    const setVideo = useVideoPlayerUpdate();

    // Modal
    const popupModalSet = useModalUpdate();

    useEffect(() => {
        console.log(getData.talkingpaper[1].slides[1].title);
    }, []);

    const openModal = (e) => {
        popupModalSet(true);
    }


    return (
        <div id="slide13">
        <div className="video-wrap">
            { <Player vid={Video_Methodology_Patients} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[1].slides[3].title}</h1>
            <div className="list-wrap flex-center">
                <div className="outer-wrap fade">
                    <div className="col-wrap">
                        <div className="col text-col fade">
                            <img className="Icon-type-1" src={Slide13_1} alt="Icon" />
                            <h2>324</h2>
                            <p>patients<br />screened</p>
                        </div>
                        <div className="col text-col fade">
                            <img className="Icon-type-1" src={Slide13_2} alt="Icon" />
                            <h2>304</h2>
                            <p>patients<br />randomized</p>
                            <img className="arrow" src={Slide13_5} alt="Arrow" />
                        </div>
                        <div className="col text-col fade">
                            <img className="Icon-type-1" src={Slide13_3} alt="Icon" />
                            <h2>300</h2>
                            <p>received at least<br />one contrast agent</p>
                            <img className="arrow" src={Slide13_5} alt="Arrow" />
                        </div>
                        <div className="col text-col fade">
                            <img className="Icon-type-1" src={Slide13_4} alt="Icon" />
                            <h2>275</h2>
                            <p>completed the trial<br />received 2 contrast agents</p>
                            <img className="arrow" src={Slide13_5} alt="Arrow" />
                        </div>
                    </div>
                    <div className="col-wrap">
                        <div className="col text-col-2 fade">
                            <img className="Icon-type-2" src={Slide13_6} alt="Icon" />
                            <div className="txt">
                                <h2>59%</h2>
                                <p>Female</p>
                            </div>
                            
                        </div>
                        <div className="col text-col-2 fade">
                            <img className="Icon-type-2" src={Slide13_7} alt="Icon" />
                            <div className="txt">
                                <h2>57 years (21–86)</h2>
                                <p>Mean age</p>
                            </div>
                            
                        </div>
                    </div>
                </div>




            </div>
        </div>
        </div>

    );
};

export default Slide13_;