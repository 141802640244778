import React, { useContext } from 'react';

// noNext
const noNextContext = React.createContext();


// current module
const currentModuleContext = React.createContext();
const currentModuleUpdateContext = React.createContext();

// screen type
const screenTypeContext = React.createContext();
const screenTypeUpdateContext = React.createContext();

// screen type
const zoomContext = React.createContext();
const zoomUpdateContext = React.createContext();

// vid playing
const vidplayContext = React.createContext();
const vidplayUpdateContext = React.createContext();

// slide direction
const slideDirectionContext = React.createContext();
const slideDirectionUpdateContext = React.createContext();

// current swiper
const currentSwiperContext = React.createContext();
const currentSwiperUpdateContext = React.createContext();

// nav from
const navFromContext = React.createContext();
const navFromUpdateContext = React.createContext();

// current module
export function useCurrentModule() {
    return useContext(currentModuleContext);
}
export function useCurrentModuleUpdate() {
    return useContext(currentModuleUpdateContext);
}

// screen type
export function useScreenType() {
    return useContext(screenTypeContext);
}
export function useScreenTypeUpdate() {
    return useContext(screenTypeUpdateContext);
}

// zoom factor
export function useZoomFactor() {
    return useContext(zoomContext);
}
export function useZoomFactorUpdate() {
    return useContext(zoomUpdateContext);
}

// vid playing
export function useVidPlaying() {
    return useContext(vidplayContext);
}
export function useVidPlayingUpdate() {
    return useContext(vidplayUpdateContext);
}

// slide direction
export function useSlideDirection() {
    return useContext(slideDirectionContext);
}
export function useSlideDirectionUpdate() {
    return useContext(slideDirectionUpdateContext);
}

// current swiper
export function useCurrentSwiper() {
    return useContext(currentSwiperContext);
}
export function useCurrentSwiperUpdate() {
    return useContext(currentSwiperUpdateContext);
}

// nav from
export function useNavFrom() {
    return useContext(navFromContext);
}
export function useNavFromUpdate() {
    return useContext(navFromUpdateContext);
}


export function MixProvider({ children }) {
    
    const [currentModule,setCurrentModule] = React.useState(0);
    const [screenType,setScreenType] = React.useState(false);
    const [zoomFactor,setZoomFactor] = React.useState();
    const [vidPlaying,setVidPlaying] = React.useState(false);
    const [slideDirection,setSlideDirection] = React.useState(false);
    const [currentSwiper,setCurrentSwiper] = React.useState();
    const [navFrom,setNavFrom] = React.useState(["intro",0]);

    function setCurrentMod(_index) {
        setCurrentModule(_index);
    } 
    function setScrType(_index) {
        setScreenType(_index);
    } 
    function setZoom(_zoom) {
        setZoomFactor(_zoom);
    } 
    function setVidPlay(_playing) {
        setVidPlaying(_playing);
    } 
    function setDirection(_direction) {
        setSlideDirection(_direction);
    } 
    function setSwiper(_swiper) {
        setCurrentSwiper(_swiper);
    } 
    function setFrom(_navfrom) {
        setNavFrom(_navfrom);
    } 
    
    return (
        <currentModuleContext.Provider value={currentModule}>
            <currentModuleUpdateContext.Provider value={setCurrentMod}>
                <vidplayContext.Provider value={vidPlaying}>
                    <vidplayUpdateContext.Provider value={setVidPlay}>
                        <zoomContext.Provider value={zoomFactor}>
                            <zoomUpdateContext.Provider value={setZoom}>
                                <navFromContext.Provider value={navFrom}>
                                    <navFromUpdateContext.Provider value={setFrom}>
                                        <screenTypeContext.Provider value={screenType}>
                                            <screenTypeUpdateContext.Provider value={setScrType}>
                                                <currentSwiperContext.Provider value={currentSwiper}>
                                                    <currentSwiperUpdateContext.Provider value={setSwiper}>
                                                        <slideDirectionContext.Provider value={slideDirection}>
                                                            <slideDirectionUpdateContext.Provider value={setDirection}>
                                                                {children}
                                                            </slideDirectionUpdateContext.Provider>
                                                        </slideDirectionContext.Provider>
                                                    </currentSwiperUpdateContext.Provider>
                                                </currentSwiperContext.Provider>
                                            </screenTypeUpdateContext.Provider>
                                        </screenTypeContext.Provider>
                                    </navFromUpdateContext.Provider>
                                </navFromContext.Provider>
                            </zoomUpdateContext.Provider>
                        </zoomContext.Provider>
                    </vidplayUpdateContext.Provider>
                </vidplayContext.Provider>
            </currentModuleUpdateContext.Provider>
        </currentModuleContext.Provider> 
    )
}
