import React, { useEffect } from 'react';

import Player from '../../components/Video/Player';
import SlideSection from '../../components/Slides/SlideSection/SlideSection';
import Header from '../../layout/Header/Header';
import Footer from '../../layout/Footer/Footer';
import { motion } from "framer-motion";

import './TalkingPaper.css'

const TalkingPaper = () => {

    useEffect(() => {
        const footnote = document.getElementById("footnotes");
        footnote.classList.remove("footnotes-wide");
    }, []);

    return (
        <div>
            <motion.div id="talking-paper" className="tp"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }} 
                transition={{ delay: .2, duration: .5 }}>
                    <div className="content">
                        { <Header navfrom="tp" /> }
                        { <SlideSection /> }
                    </div>
            </motion.div>
        </div>
    );
};

export default TalkingPaper;