import React, { useEffect, useLayoutEffect, useState } from 'react';
import { ModalProvider } from "./components/context/ModalContext";
import { MixProvider } from "./components/context/MixContext";
import { DataProvider } from "./components/context/DataContext";
import { VideoProvider } from "./components/context/VideoContext";
import Content from './layout/Content/Content';
import PopupModal from "./components/PopupModal/PopupModal";
import Footer from './layout/Footer/Footer';

import './assets/webFonts/GuerbetEluciremQuiz.css'
import './App.css';

function App() {

  useEffect(() => {


    



  let resizeRefresh = document.querySelectorAll( ".site" );
  let wrapperScales = document.querySelectorAll( ".site" );

  onScaleStContainer();
  setTimeout(onScaleStContainer, 100);

  window.onresize = function () {
    clearTimeout(resizeRefresh);
    resizeRefresh = setTimeout(onScaleStContainer, 50);
  };

  window.onpopstate = function (event) {
    wrapperScales = document.querySelectorAll( ".site" );
    onScaleStContainer();
  };

  function onScaleStContainer() {
    let w = document.body.clientWidth;
    let h = document.body.clientHeight;

    wrapperScales.forEach(function (scaleElement) {
      scaleElement.classList.add("scale-content-init");
      let scale = w / (scaleElement.offsetWidth === 1366 ? 1366 : scaleElement.offsetWidth);
      let scaleH = h / (scaleElement.offsetHeight === 768 ? 768 : scaleElement.offsetHeight);
      let newscale = scale > scaleH ? scaleH : scale;
      if ( newscale < 1 ) {
        scaleElement.style.transform = "scale(" + newscale + ") translate(-50%, 0)";
      } else {
        scaleElement.style.transform = "scale(1) translate(-50%, 0)";
      }
      scaleElement.style.transformOrigin = "0 0";
      scaleElement.style.position = "absolute";
      scaleElement.style.left = "50%";
      scaleElement.style.top = "0";
    });
  }





  



  }, []);

  





  return (
    <div id="site" className="site">
      <div className="wrapper">
        <DataProvider>
          <MixProvider>
            <VideoProvider>
              <ModalProvider>
                <Content />
                <PopupModal />
              </ModalProvider>
            </VideoProvider>
          </MixProvider>
        </DataProvider>
        <Footer />
      </div>
    </div>
  );
}

export default App;
