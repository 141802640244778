import React, { useEffect } from 'react';

import { useScreenType, useScreenTypeUpdate } from "../../components/context/MixContext";

// import Header from '../../layout/Header/Header';
import Player from '../../components/Video/Player';

import { motion } from "framer-motion";

import './Intro.css';
import { Video_Presentation } from '../../assets/video';

const Intro = () => {

    // screen type
    const getScreenType = useScreenType();
    const setScreenType = useScreenTypeUpdate();

    const introNav = (_navtype) => {
        if ( _navtype === "prev") {
            setScreenType("home");
        } else {
            setScreenType("tp");
        }  
    }

    useEffect(() => {
        const footnote = document.getElementById("footnotes");
        footnote.classList.remove("footnotes-wide");
        footnote.innerHTML = "<p></p>";
    }, []);

    return (

        <motion.div id="intro"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }} 
            transition={{ delay: .2, duration: .5 }}>
                {/* { <Header navfrom="intro" /> } */}
                <div className="intro">
                    <h1 className="underline">The PROMISE Trial</h1>
                    <div className="intro-wrap">
                        <div className="intro-column column1">
                            <h1>Efficacy and safety of half-dose gadopiclenol versus full-dose gadobutrol for contrast-enhanced body MRI</h1>
                            <div className="fade-line"></div>
                            <h2>The PROMISE Randomized Clinical Trial</h2>
                            <p>Kuhl C, Csőszi T, Piskorski W, et al. Efficacy and safety of half-dose gadopiclenol versus fulldose gadobutrol for contrast-enhanced body MRI. Radiology.
2023 Jul;308(1):e222612.</p>
                        </div>
                        <div className="intro-column column2">
                            <div className="intro-video-wrap">
                            { <Player vid={Video_Presentation} /> }
                            </div>
                            <p>Hear insights on the PROMISE Trial from</p>
                            <h2>Professor Christiane Kuhl</h2>
                            <p className="footnote">February 2024</p>
                        </div>
                    </div>
                </div>

                <div className="button-parent">
                <div className="button-wrapper">
                    <div id="swiper-prev-btn" className="swiper-btn" onClick={() => { introNav("prev"); }}></div>
                    <div id="swiper-next-btn" className="swiper-btn" onClick={() => { introNav("next"); }}></div>
                </div>

                <div className="footnotes"><p>The statements made in this presentation reflect only the personal opinions and experiences of the speaker and do not necessarily reflect the opinions of Guerbet or any other institutions with which they are affiliated.<br/>Please consult the prescribing information in your country.</p></div>
            </div>

        </motion.div>
    );
};

export default Intro;