import React, { useEffect } from 'react';
import { useData } from "../../context/DataContext";

import Player from '../../Video/Player';
import { useModalUpdate, useModalContentUpdate } from "../../context/ModalContext";

import { Slide01_1 } from '../../../assets/Images';

import './Slide.css';
import './Slide02.css';
import { Video_Intro_Hypothesis } from '../../../assets/video';

const Slide01 = () => {

    // get/set json data
    const getData = useData();

    // Modal
    const popupModalSet = useModalUpdate();

    // Modal content
    const modalContentSet = useModalContentUpdate();

    useEffect(() => {
        
    }, []);

    const openModal = (e) => {
        modalContentSet(e);
        popupModalSet(true);
    }

    return (
        <div id="slide02">
        <div className="video-wrap">
            { <Player vid={Video_Intro_Hypothesis} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[0].slides[2].title}</h1>
            
            <div className='slide-body-wrap'>
                <div className="text-wrap fade">
                    <div className="text-comp">
                    <p>Based on the results of a phase IIb study, we hypothesized that <span>ELUCIREM™ at half the standard dose (0.05 mmol/kg),</span> is noninferior to a state-of-the-art macrocyclic GBCA (gadobutrol) <span>at the full standard dose of 0.1 mmol/kg</span> for contrast-enhanced body lesion visualization</p>
                    </div>
                </div>
            </div>
        </div>
        </div>

    );
};

export default Slide01;