import React, { useEffect } from 'react';

import { useCurrentModule, useCurrentModuleUpdate } from "../../components/context/MixContext";

import { useData, useDataUpdate } from "../../components/context/DataContext";
import { useZoomFactor, useZoomFactorUpdate, useScreenType, useScreenTypeUpdate, useNavFrom, useNavFromUpdate } from "../../components/context/MixContext";
import Home from '../../components/Home/Home';
import Intro from '../../components/Intro/Intro';
import TalkingPaper from '../../components/TalkingPaper/TalkingPaper';

import './Content.css';




const Content = () => {

    // get/set json data
    const getData = useData();
    const setData = useDataUpdate();

    // current module
    const getModule = useCurrentModule();
    const setModule = useCurrentModuleUpdate();

    // screen type
    const getScreenType = useScreenType();
    const setScreenType = useScreenTypeUpdate();

    // Zoom
    const getZoom = useZoomFactor();
    const setZoom = useZoomFactorUpdate();

    // nav from
    const getNavFrom = useNavFrom();
    const setNavFrom = useNavFromUpdate();

    window.addEventListener('resize', () => {
        const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
        setZoom(browserZoomLevel);
    })

    useEffect(() => {

        fetch('./content.json'
    ,{
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
       }
    }
    )
    .then(function(response){
        return response.json();
    })
    .then(function(dataJson) {
        setData(dataJson);
        const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
        setZoom(browserZoomLevel);
        setScreenType("home")
    });
    }, []) 

    const logoNav = () => {
        console.log("oop")
        setScreenType("home");
        setModule(0);
        setNavFrom(["tp", 0]);
      }

  

    return (
        <>
            { getScreenType === 'home' ? <Home /> : null }
            { getScreenType === 'intro' ? <Intro /> : null }
            { getScreenType === 'tp' ? <TalkingPaper /> : null }
            <div className="restart" onClick={() => { logoNav(); }}></div>
        </>
    );
};

export default Content;