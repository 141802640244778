import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFade, EffectCube, Pagination, A11y } from 'swiper/modules';
import { motion } from "framer-motion";
import { useCurrentModule, useCurrentModuleUpdate, useSlideDirection, useSlideDirectionUpdate, useCurrentSwiper, useCurrentSwiperUpdate, useScreenType, useScreenTypeUpdate, useNavFrom, useNavFromUpdate } from "../../components/context/MixContext";
import { useData, useDataUpdate } from "../../components/context/DataContext";
import { renderFormattedText } from "../../utils/helpers";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';
import 'swiper/css/effect-cube';
import './MultiSwiper.css'

const MultiSwiper = (props) => {
    console.log(props)

    const [currentSwiper, setCurrentSwiper] = useState(null);
    const [noPrev, setNoPrev] = useState();
    const [noNext, setNoNext] = useState(props.nonext);

    const swiperRef = useRef();

    // get/set json data
    const getData = useData();
    const setData = useDataUpdate();

    // current module
    const getModule = useCurrentModule();
    const setModule = useCurrentModuleUpdate();

    // slide direction
    const getDirection = useSlideDirection();
    const setDirection = useSlideDirectionUpdate();

    // current swiper
    const getSwiper = useCurrentSwiper();
    const setSwiper = useCurrentSwiperUpdate();

    // screen type
    const getScreenType = useScreenType();
    const setScreenType = useScreenTypeUpdate();

    // nav from
    const getNavFrom = useNavFrom();
    const setNavFrom = useNavFromUpdate();

    const swiperNav = ( _swiper, _direction ) => {
        if ( _direction === "next") {
            setDirection("next");
            if (!_swiper.isEnd) {
                _swiper.slideNext();
            } else {
                if (getModule === getData.talkingpaper.length-1) {
                    setScreenType("intro");
                    setModule(0);
                    setNavFrom(["tp", 0]);
                } else {
                    setModule(getModule+1);
                    setNavFrom(["tp", 0]);
                }
                
            }
        } else {
            setDirection("prev");
            if (!_swiper.isBeginning) {
                _swiper.slidePrev();
            } else {
                if (getModule === 0) {
                    setScreenType("intro");
                    setModule(0);
                    setNavFrom(["tp", 0]);
                } else {
                    setModule(getModule-1);
                    setNavFrom(["tp", getData.talkingpaper[getModule-1].slides.length]);
                }
                
            }
        }
    }
    

    let lastSlide
    useEffect(() => {
        setNoNext(lastSlide)
    }, []);

    return (
        <div className="swiper-holder">
            <motion.div className="tp"
                // initial={{ opacity: 0, x: 1366 }}
                // animate={{ opacity: 1, x: 0 }} 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }} 
                transition={{ delay: .4, duration: .8 }}>
                <Swiper
                    id="mySwiper"
                    // className={props.classname}
                    className={props.classname}
                    observer={true}
                    observeParents={true}
                    autoHeight= {true}
                    modules={[Pagination, Navigation, EffectFade, EffectCube, A11y]}
                    // effect={'cube'}
                    // cubeEffect={{shadow: false}}
                    effect={'fade'}
                    fadeEffect={{crossFade: true}}
                    speed={1000}
                    spaceBetween={2}
                    slidesPerView={1}
                    noSwiping={true}
                    navigation={false}
                    noSwipingClass='noswiper'
                    pagination={{
                        el: `#${props.pagination}`,
                        type: "bullets",
                        bulletClass: "swiper-custom-bullet",
                        bulletActiveClass: "swiper-custom-bullet-active",
                        clickable: true,
                }}
                    // centeredSlides={true}
                    onSlideChange={(swiper) => {
                        //
                        // console.log(swiper.realIndex);
                        // setNoPrev(props.data.slides[swiper.realIndex].noprev);
                        // setNoNext(props.data.slides[swiper.realIndex].nonext);

                        lastSlide = props.data.slides[swiper.realIndex].pagination

                        console.log(props.data.slides[swiper.realIndex].pagination);
                        const footnote = document.getElementById("footnotes");
                        footnote.innerHTML = props.data.slides[swiper.realIndex].footnote;
                    }}
                    onSwiper={(swiper) => {
                        setCurrentSwiper(swiper);
                        setSwiper(swiper);
                    }}
                    onAfterInit={(swiper) => {

                        const footnote = document.getElementById("footnotes");
                        footnote.innerHTML = props.data.slides[swiper.realIndex].footnote;

                        swiperRef.current = swiper;
                        swiper.slideTo(getNavFrom[1]);
   
                        // if ( getDirection === "prev" ) {
                        //     swiper.slideTo(2, 0)
                        // }
                        // swiper.slideTo(getNavFrom[1]);
                    }}
                    onBeforeSlideChangeStart={(swiper) => {
                        // 
                        // const footnote = document.getElementById("footnotes");
                        // footnote.innerHTML = props.data.slides[swiper.realIndex].footnote;
                    }}
                    onBeforeInit={(swiper) =>{
                        // 
                        // swiperRef.current = swiper;
                        // swiper.slideTo(getNavFrom[1]);

                    }}
                    onReachEnd={(swiper) => {
                        // console.log("end of slides");
                        // setModule(2)
                    }}
                >
                    {props.slides.map(function(d, index){
                        return (
                            <SwiperSlide key={index} className="noswiper">
                                {d}
                            </SwiperSlide>
                        )
                        
                    })}


                </Swiper>
            </motion.div>
            <div className="button-parent">
                <div className="button-wrapper">
                    <div id="swiper-prev-btn" className={noPrev ? "swiper-btn swiper-btn-disabled" : "swiper-btn" } onClick={() => { swiperNav(currentSwiper, "prev"); }}></div>
                    {props.slides.length > 1 && <div id={props.pagination}></div>}
                    <div id="swiper-next-btn" className={noNext ? "swiper-btn swiper-btn-disabled" : "swiper-btn" } onClick={() => { swiperNav(currentSwiper, "next"); }}></div>
                </div>
            </div>               
        </div>
    );
};

export default MultiSwiper;