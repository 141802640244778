import React, { useEffect } from 'react';
import { useData } from "../../../components/context/DataContext";

import Player from '../../../components/Video/Player';
import { useModal, useModalUpdate } from "../../context/ModalContext";

import { useVideoPlayer, useVideoPlayerUpdate } from "../../../components/context/VideoContext";
import { Slide12_1 } from '../../../assets/Images';




import './Slide.css';
import './Slide12.css';
import { Video_Methodology_Efficacy } from '../../../assets/video';

const Slide12 = () => {

    // get/set json data
    const getData = useData();

    // get/set json data
    const getVideo = useVideoPlayer();
    const setVideo = useVideoPlayerUpdate();

    // Modal
    const popupModalSet = useModalUpdate();

    useEffect(() => {
        console.log(getData.talkingpaper[1].slides[1].title);
    }, []);

    const openModal = (e) => {
        popupModalSet(true);
    }


    return (
        <div id="slide12">
        <div className="video-wrap">
            { <Player vid={Video_Methodology_Efficacy} /> }
        </div>
        <div className="slide">
            <h1>{getData.talkingpaper[1].slides[2].title}</h1>

            <div className="slide-body-wrap">
                <div className="slide-copy-wrap">
                    <div className="slide-copy">
                        <p>19 different off-site, independent and experienced readers involved in the study</p>
                    </div>
                </div>
                <div className="image-wrap flex-center fade">
                    <img src={Slide12_1} alt="Chart" />
                </div>
                <div className="text-col">
                    <ul>
                        <li>Finally, a 19th off-site reader performed lesion tracking to match lesions across the two different imaging studies of each participant</li>
                        <li>In addition, at least one experienced radiologist was appointed to read images on site</li>
                    </ul>
                </div>
            </div>
        </div>
        </div>

    );
};

export default Slide12;